import React        from "react"
import {NewsLayout} from "sections/News/"
import useNewsData  from "shared/Hooks/News/useNewsData"
import {Gallery}    from "src/sections/News/NewsLayout/Gallery";
import {TextBlock}  from "src/sections/News/NewsLayout/TextBlock";
import {NEWS_4}     from "types/News"

const Article4 = () => {
  const data = useNewsData(NEWS_4);

  return (
    <NewsLayout data={data}>
      <TextBlock data={data.texts.first}/>
      <Gallery data={data.gallery}/>
    </NewsLayout>
  )
}

export default Article4;
